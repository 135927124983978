/* Sizes */
.bdmAdvisersTable-component__bdm-advisers-table___Yx2mi {
  position: relative;
  display: flex;
  align-items: center; }
  .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi.bdmAdvisersTable-component__active___dzUjg button {
    color: #ccae44; }
  .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__mobileOnly___8hfhS button {
    padding: 0 1rem 0 0;
    border: 0;
    height: auto;
    line-height: inherit; }
  .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E {
    position: absolute;
    top: 6rem;
    display: none; }
    @media (min-width: 600px) {
      .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E {
        position: relative;
        top: auto;
        display: block; } }
  .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi.bdmAdvisersTable-component__active___dzUjg .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E {
    width: 100vw;
    right: 0;
    display: block; }
  .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E {
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    background-color: #363431; }
    .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E button {
      height: auto;
      border: 0; }
    .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E .bdmAdvisersTable-component__row___30UfG {
      display: flex;
      flex-direction: row; }
    .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E .bdmAdvisersTable-component__welcomeBack___1siMZ,
    .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E .bdmAdvisersTable-component__userName___29d6u,
    .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E .bdmAdvisersTable-component__lastLogin___3Mfjt {
      color: #edede9;
      margin-bottom: 0;
      text-transform: none;
      text-align: start;
      font-family: "Maitree", serif;
      line-height: normal; }
    .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E .bdmAdvisersTable-component__userName___29d6u {
      text-transform: uppercase;
      font-family: "Gotham-Medium", Arial, sans-serif;
      font-size: 1.6rem;
      margin-right: 2rem;
      margin-top: 2rem;
      margin-left: 2rem; }
    .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E .bdmAdvisersTable-component__accountMenuButton___2owcv {
      color: #edede9;
      border-bottom: 1px solid #fff;
      border-radius: 0;
      padding: 1rem 0px;
      font-size: 1.6rem;
      width: 100%;
      text-align: left;
      text-transform: none;
      font-family: "Maitree", serif; }
      .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E .bdmAdvisersTable-component__accountMenuButton___2owcv:first-of-type {
        border-top: 1px solid #fff; }
      .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__fullAccountMenuContainer___1qd6E .bdmAdvisersTable-component__accountMenuButton___2owcv:last-of-type {
        border-bottom: 0; }
  .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi .bdmAdvisersTable-component__openAccountMenuContainer___3BXNR {
    position: absolute;
    top: 5.9rem;
    width: 100vw;
    flex-direction: column;
    display: flex;
    background-color: #363431;
    width: 100%;
    align-items: baseline;
    padding: 0 30px; }
  @media (min-width: 600px) {
    .bdmAdvisersTable-component__bdm-advisers-table___Yx2mi.bdmAdvisersTable-component__active___dzUjg .bdmAdvisersTable-component__openAccountMenuContainer___3BXNR {
      left: 0;
      top: 100%;
      right: 0; } }

.bdmAdvisersTable-component__boolCheckboxContainer___23XSZ {
  margin-bottom: 2rem; }
  .bdmAdvisersTable-component__boolCheckboxContainer___23XSZ .bdmAdvisersTable-component__boolCheckbox___Rr_g0 {
    color: #ffffff;
    z-index: 999;
    display: flex;
    flex-direction: row;
    cursor: pointer; }
