/* Sizes */
.PrimaryContent-component__primaryContentContainer___yzAs6 {
  background-color: #eeebe6;
  padding: 8rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  padding-top: 0rem; }
  @media (min-width: 600px) {
    .PrimaryContent-component__primaryContentContainer___yzAs6 {
      padding-top: 6rem; } }
  .PrimaryContent-component__primaryContentContainer___yzAs6:before {
    content: "";
    max-height: 50rem;
    min-height: 10rem;
    height: 40%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #363431; }
    @media (bp-sm) {
      .PrimaryContent-component__primaryContentContainer___yzAs6:before {
        min-height: 17rem; } }
  .PrimaryContent-component__primaryContentContainer___yzAs6.PrimaryContent-component__offsetIntoBanner___3LYWd {
    background-color: unset;
    margin-top: -8rem;
    z-index: 10;
    padding-top: 0; }
    .PrimaryContent-component__primaryContentContainer___yzAs6.PrimaryContent-component__offsetIntoBanner___3LYWd:before {
      display: none; }
  .PrimaryContent-component__primaryContentContainer___yzAs6:before {
    content: "";
    max-height: 50rem;
    min-height: 10rem;
    height: 40%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #363431; }
    @media (bp-sm) {
      .PrimaryContent-component__primaryContentContainer___yzAs6:before {
        min-height: 17rem; } }
  .PrimaryContent-component__primaryContentContainer___yzAs6 .PrimaryContent-component__contentHeader___36IcV {
    color: #ffffff;
    font-family: "Maitree", serif;
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 3rem;
    z-index: 10;
    margin-top: 2rem; }
    @media (bp-sm) {
      .PrimaryContent-component__primaryContentContainer___yzAs6 .PrimaryContent-component__contentHeader___36IcV {
        margin-top: 0; } }
