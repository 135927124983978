/* Sizes */
.bannerButtons-component__bannerButtons___37iCm {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  z-index: 1000;
  margin-top: 3rem;
  margin-bottom: 2rem; }
  @media (min-width: 600px) {
    .bannerButtons-component__bannerButtons___37iCm {
      max-width: 34rem; } }
  .bannerButtons-component__bannerButtons___37iCm a {
    border: 0;
    border-radius: 0;
    color: #ffffff;
    font-family: "Gotham-Medium", Arial, sans-serif;
    font-size: 1.6rem;
    font-weight: 500;
    left: auto;
    bottom: auto;
    max-width: auto;
    height: auto;
    padding: 0.6rem;
    text-decoration: none;
    text-align: center; }
    .bannerButtons-component__bannerButtons___37iCm a:first-of-type {
      width: 100%;
      background-color: #ccae44;
      display: none; }
    .bannerButtons-component__bannerButtons___37iCm a:nth-of-type(2) {
      width: 40%;
      background-color: #5e5b57; }
    .bannerButtons-component__bannerButtons___37iCm a:last-of-type {
      width: 60%;
      background-color: #363431;
      display: none; }
  .bannerButtons-component__bannerButtons___37iCm a:hover {
    opacity: 0.7;
    color: #ffffff; }
