/* Sizes */
.Alert-component__alert___3LtXX .Alert-component__button___1ORiz {
  border: none; }
  .Alert-component__alert___3LtXX .Alert-component__button___1ORiz:hover {
    border: none;
    opacity: 0.7; }
  .Alert-component__alert___3LtXX .Alert-component__button___1ORiz.Alert-component__primary___3JsPg {
    color: #ccae44; }
  .Alert-component__alert___3LtXX .Alert-component__button___1ORiz.Alert-component__secondary___3JulL {
    color: #9e9c9a; }

.Alert-component__alert___3LtXX .Alert-component__title___iYhuT {
  flex: 0 0 auto;
  margin: 0;
  padding: 24px 24px 20px; }

.Alert-component__alert___3LtXX .Alert-component__message___1w-2i {
  font-size: 1.2rem; }
