/* Sizes */
.personalBdm-component__infoContainer___1VtEJ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem; }
  .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__name___1td29 {
    text-transform: uppercase;
    font-family: "Gotham-Medium", Arial, sans-serif;
    font-size: 2.4rem;
    margin-top: 1rem;
    display: inline-flex;
    align-items: center; }
    .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__name___1td29.personalBdm-component__icon___1s6ab {
      width: 2.4rem;
      height: 2.4rem;
      margin: 0rem 0.5rem 0rem 0rem; }
  .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__personalBdmTitle___1NCLl {
    text-transform: uppercase;
    font-family: "Gotham-Medium", Arial, sans-serif;
    font-size: 1.6rem;
    color: #ccae44;
    text-align: center; }
  @media (min-width: 600px) {
    .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__personalBdmTitle___1NCLl {
      text-align: left; } }
  .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__contactInfo___3idxz {
    font-size: 1.6rem;
    display: inline-flex;
    align-items: center; }
    .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__contactInfo___3idxz svg {
      font-size: 1.8rem;
      padding: 0px;
      margin-right: 0.5rem; }
    .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__contactInfo___3idxz span {
      display: block; }
  .personalBdm-component__infoContainer___1VtEJ svg {
    font-size: 1.6rem;
    padding-top: 0.3rem; }
  .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__cardTitle___20GiD {
    font-family: "Maitree", serif;
    font-size: 2.4rem;
    position: absolute;
    top: 2rem;
    left: 0;
    right: 0;
    text-align: center; }
    .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__cardTitle___20GiD.personalBdm-component__shortText___38grN {
      display: none; }
  @media (min-width: 600px) {
    .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__cardTitle___20GiD {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      text-align: left; } }
  @media (max-width: 750px) {
    .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__cardTitle___20GiD.personalBdm-component__shortText___38grN {
      display: block; }
    .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__cardTitle___20GiD.personalBdm-component__fullText___2yapj {
      display: none; } }
  .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__bookMeetingContainer___1qomf {
    display: flex;
    flex-direction: column;
    margin-top: 2rem; }
    .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__bookMeetingContainer___1qomf button {
      width: 22rem;
      height: 5rem;
      border: 2px solid #363431;
      border-radius: 0px 0px 0px 0px;
      color: #363431;
      font-size: 1.5rem;
      font-family: "Gotham-Medium", Arial, sans-serif;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase; }
    .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__bookMeetingContainer___1qomf b,
    .personalBdm-component__infoContainer___1VtEJ .personalBdm-component__bookMeetingContainer___1qomf strong {
      font-weight: 900;
      font-family: Univers; }

@media (min-width: 600px) {
  .personalBdm-component__infoContainer___1VtEJ {
    align-items: flex-start; } }

.personalBdm-component__photoContainer___1yWYu {
  display: flex;
  justify-content: center;
  align-items: center; }

.personalBdm-component__photo___1JC-C {
  width: 100vw;
  margin-top: 4rem;
  min-width: 180px; }

@media (min-width: 600px) {
  .personalBdm-component__photo___1JC-C {
    width: 30vw;
    margin-top: 0; } }

.personalBdm-component__justifyCenterMobile___d77rY {
  justify-content: center; }
  @media (min-width: 600px) {
    .personalBdm-component__justifyCenterMobile___d77rY {
      justify-content: inherit; } }

.personalBdm-component__textCenterMobile___3W6Gc {
  text-align: center; }
  @media (min-width: 600px) {
    .personalBdm-component__textCenterMobile___3W6Gc {
      text-align: inherit; } }
