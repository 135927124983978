/* Sizes */
.newsEntry-component__newsItem___2GWMF {
  background-color: #fff;
  padding: 2rem;
  height: 100%;
  display: flex;
  flex-direction: column; }
  .newsEntry-component__newsItem___2GWMF.newsEntry-component__detailed___GGO3M {
    margin: 4rem;
    padding: 6rem;
    width: 80vw;
    align-self: center;
    left: auto;
    right: auto;
    display: flex;
    z-index: 10; }
    .newsEntry-component__newsItem___2GWMF.newsEntry-component__detailed___GGO3M .newsEntry-component__newsTitle___2gyT6 {
      text-align: center; }
  .newsEntry-component__newsItem___2GWMF .newsEntry-component__newsTitle___2gyT6 {
    font-family: "Gotham-Medium", Arial, sans-serif;
    font-size: 1.6rem;
    margin: 1rem 0; }
  .newsEntry-component__newsItem___2GWMF .newsEntry-component__newsText___1pJJS {
    font-family: "Univers-Light", Arial, sans-serif;
    font-size: 1.4rem;
    margin-bottom: 1rem 0; }

.newsEntry-component__newsItemImg___1iENZ {
  width: 70%;
  align-self: center;
  margin-bottom: 1rem; }

.newsEntry-component__single-post___1zguM #newsEntry-component__main___3Jguo .newsEntry-component__entry-content___3wvF0 hr {
  margin: 0 3rem 3rem 0;
  width: 75px;
  background-color: #ccae44;
  height: 2px;
  border: 0;
  vertical-align: top;
  display: inline-block; }
