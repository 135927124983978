/* Sizes */
.burgerMenu-component__burgerMenuContainer___2y9cI {
  position: relative;
  display: flex;
  align-items: center;
  width: 5rem; }
  .burgerMenu-component__burgerMenuContainer___2y9cI button.burgerMenu-component__burgerMenuToggle___1jhVV {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    font-size: 4rem;
    padding: 0;
    border: 0; }
    .burgerMenu-component__burgerMenuContainer___2y9cI button.burgerMenu-component__burgerMenuToggle___1jhVV svg {
      font-size: 4.2rem;
      line-height: 4.2rem; }
  .burgerMenu-component__burgerMenuContainer___2y9cI.burgerMenu-component__active___3excf button {
    color: #ccae44; }
  .burgerMenu-component__burgerMenuContainer___2y9cI .burgerMenu-component__mobileOnly___26SAy button {
    padding: 0 1rem 0 0;
    border: 0;
    height: auto;
    line-height: inherit; }
  .burgerMenu-component__burgerMenuContainer___2y9cI .burgerMenu-component__fullBurgerMenuContainer___eHmM8 {
    position: absolute;
    top: 6rem;
    display: none; }
    @media (min-width: 600px) {
      .burgerMenu-component__burgerMenuContainer___2y9cI .burgerMenu-component__fullBurgerMenuContainer___eHmM8 {
        position: relative;
        top: auto;
        display: block; } }
  .burgerMenu-component__burgerMenuContainer___2y9cI.burgerMenu-component__active___3excf .burgerMenu-component__fullBurgerMenuContainer___eHmM8 {
    width: 100vw;
    right: 0;
    display: block; }
    @media (min-width: 600px) {
      .burgerMenu-component__burgerMenuContainer___2y9cI.burgerMenu-component__active___3excf .burgerMenu-component__fullBurgerMenuContainer___eHmM8 {
        width: auto;
        right: auto; } }
  .burgerMenu-component__burgerMenuContainer___2y9cI .burgerMenu-component__fullBurgerMenuContainer___eHmM8 {
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    background-color: #ffffff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }
    .burgerMenu-component__burgerMenuContainer___2y9cI .burgerMenu-component__fullBurgerMenuContainer___eHmM8 button {
      height: auto;
      border: 0; }
    .burgerMenu-component__burgerMenuContainer___2y9cI .burgerMenu-component__fullBurgerMenuContainer___eHmM8 .burgerMenu-component__row___3o5iO {
      display: flex;
      flex-direction: row; }
    .burgerMenu-component__burgerMenuContainer___2y9cI .burgerMenu-component__fullBurgerMenuContainer___eHmM8 button.burgerMenu-component__burgerMenuToggle___1jhVV {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      font-size: 4rem; }
    .burgerMenu-component__burgerMenuContainer___2y9cI .burgerMenu-component__fullBurgerMenuContainer___eHmM8 .burgerMenu-component__burgerMenuButton___2idHB {
      color: #363431;
      border-bottom: 1px solid #363431;
      border-radius: 0;
      padding: 1rem 0px;
      font-size: 1.6rem;
      width: 100%;
      text-align: left;
      text-transform: none;
      font-family: "Maitree", serif; }
      .burgerMenu-component__burgerMenuContainer___2y9cI .burgerMenu-component__fullBurgerMenuContainer___eHmM8 .burgerMenu-component__burgerMenuButton___2idHB:first-of-type {
        border-top: 1px solid #363431; }
      .burgerMenu-component__burgerMenuContainer___2y9cI .burgerMenu-component__fullBurgerMenuContainer___eHmM8 .burgerMenu-component__burgerMenuButton___2idHB:last-of-type {
        border-bottom: 0; }
  .burgerMenu-component__burgerMenuContainer___2y9cI .burgerMenu-component__openBurgerMenuContainer___C_ih5 {
    position: absolute;
    top: 0;
    width: 100vw;
    flex-direction: column;
    display: flex;
    background-color: #ffffff;
    width: 100%;
    align-items: baseline;
    padding: 0 30px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }
  @media (min-width: 600px) {
    .burgerMenu-component__burgerMenuContainer___2y9cI.burgerMenu-component__active___3excf .burgerMenu-component__openBurgerMenuContainer___C_ih5 {
      left: 0;
      top: 100%;
      right: 0; } }

@media (min-width: 600px) {
  .burgerMenu-component__burgerMenuContainer___2y9cI {
    display: none; } }
