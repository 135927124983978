/* Sizes */
.clientDocuments-component__clientDocumentsContainer___3bSgV {
  margin-top: 4rem;
  padding-bottom: 4rem; }
  .clientDocuments-component__clientDocumentsContainer___3bSgV h3 {
    margin-left: 2rem;
    height: 2.4rem;
    color: #5e5b57;
    font-size: 2.4rem;
    font-family: "Gotham-Medium", Arial, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 1.6rem; }
    @media (min-width: 600px) {
      .clientDocuments-component__clientDocumentsContainer___3bSgV h3 {
        margin-left: 0; } }

.clientDocuments-component__documentSelectBox___RsOJW {
  color: #e4e4e4;
  cursor: pointer; }

.clientDocuments-component__documentSelectBoxChecked___JVX40 {
  color: #ccae44;
  cursor: pointer; }

.clientDocuments-component__unread___2HVbR .clientDocuments-component__document-name___C8F-o {
  font-weight: bold;
  font-family: sans-serif; }
