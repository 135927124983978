/* Sizes */
.header-component__header___1cr3Z {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  width: 100%;
  z-index: 100;
  background: white; }
  .header-component__header___1cr3Z .header-component__logo-container___2XrbZ {
    display: flex;
    align-items: center;
    position: relative;
    padding: 1rem;
    flex: 1; }
  .header-component__header___1cr3Z .header-component__logo___sEEsb {
    height: auto;
    display: block;
    max-height: 4rem; }
    @media (min-width: 600px) {
      .header-component__header___1cr3Z .header-component__logo___sEEsb {
        display: none; } }
  .header-component__header___1cr3Z .header-component__largeLogo___3r3tg {
    display: none;
    max-height: 7rem; }
    @media (min-width: 600px) {
      .header-component__header___1cr3Z .header-component__largeLogo___3r3tg {
        display: block; } }
  .header-component__header___1cr3Z .header-component__row___S-LJf {
    display: flex;
    flex-direction: row; }
  .header-component__header___1cr3Z .header-component__textLinksContainer___22iHD,
  .header-component__header___1cr3Z .header-component__iconLinksContainer___M3Rql {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 1rem; }
    .header-component__header___1cr3Z .header-component__textLinksContainer___22iHD a,
    .header-component__header___1cr3Z .header-component__iconLinksContainer___M3Rql a {
      margin-left: 1rem;
      margin-right: 1rem;
      text-decoration: none; }
  .header-component__header___1cr3Z .header-component__textLinksContainer___22iHD {
    display: none; }
    @media (min-width: 750px) {
      .header-component__header___1cr3Z .header-component__textLinksContainer___22iHD {
        display: flex;
        width: auto; } }
