/* Sizes */
.investmentsOverview-component__flipHorizontally___3kjIC, .investmentsOverview-component__container___3oNhy .investmentsOverview-component__backgroundContainer___2IW_s.investmentsOverview-component__adapt___1dovn {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: "FlipH"; }

.investmentsOverview-component__resetTransform___4KVWD {
  -moz-transform: none;
  -o-transform: none;
  -webkit-transform: none;
  transform: none;
  filter: none;
  -ms-filter: none; }

.investmentsOverview-component__gridContainer___2RChT {
  justify-content: center; }

.investmentsOverview-component__gridContainer___2RChT > .investmentsOverview-component__gridItemContainer___1o7LR {
  padding: 24px;
  padding-bottom: 12px; }
  .investmentsOverview-component__gridContainer___2RChT > .investmentsOverview-component__gridItemContainer___1o7LR:last-child {
    padding-bottom: 24px; }

.investmentsOverview-component__cardContainer___2-hOJ {
  min-height: 31rem; }
  .investmentsOverview-component__cardContainer___2-hOJ h3 {
    margin-top: 2rem;
    margin-left: 2rem;
    color: #eeebe6;
    font-family: Gotham-Medium;
    font-size: 24px;
    font-weight: 500;
    height: 24px;
    text-transform: uppercase;
    font-family: "Gotham-Medium", Arial, sans-serif; }
    @media (min-width: 600px) {
      .investmentsOverview-component__cardContainer___2-hOJ h3 {
        margin-top: 0;
        margin-left: 0; } }
    .investmentsOverview-component__cardContainer___2-hOJ h3:first-of-type {
      margin-bottom: 1rem; }

.investmentsOverview-component__container___3oNhy {
  position: relative;
  background-color: #5e5b57;
  color: #ffffff;
  cursor: pointer;
  height: 15rem;
  padding: 2rem;
  font-size: 2.4rem;
  line-height: 3.2rem;
  font-family: "Maitree", serif;
  z-index: 2;
  min-width: 22rem; }
  .investmentsOverview-component__container___3oNhy .investmentsOverview-component__backgroundContainer___2IW_s {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-size: 90%;
    background-repeat: no-repeat;
    background-position: bottom right;
    background-position-y: 5rem;
    background-image: url("/assets/img/flying.png");
    z-index: -1; }
    .investmentsOverview-component__container___3oNhy .investmentsOverview-component__backgroundContainer___2IW_s.investmentsOverview-component__adapt___1dovn {
      background-color: #8ecfd2;
      background-image: url("/assets/img/BF_Illustrations_19.png");
      background-position: bottom left;
      background-position-y: 6rem;
      background-position-x: -2rem; }
    .investmentsOverview-component__container___3oNhy .investmentsOverview-component__backgroundContainer___2IW_s.investmentsOverview-component__evolve___3MzzK {
      background-color: #5b2c50;
      background-image: url("/assets/img/BF_Illustrations_14.png");
      background-size: 70%;
      background-position-x: 8rem;
      background-position-y: 6rem; }
    .investmentsOverview-component__container___3oNhy .investmentsOverview-component__backgroundContainer___2IW_s.investmentsOverview-component__ventures___3jq9A {
      background-color: #163944;
      background-image: url("/assets/img/Blackfinch-Ventures-3-birds.png");
      background-position-x: 7rem;
      background-position-y: 7rem; }
  .investmentsOverview-component__container___3oNhy:hover {
    opacity: 0.8; }
  @media (min-width: 600px) {
    .investmentsOverview-component__container___3oNhy {
      height: 16rem; } }
  @media (min-width: 960px) {
    .investmentsOverview-component__container___3oNhy {
      height: 16rem; } }
  @media (min-width: 1280px) {
    .investmentsOverview-component__container___3oNhy {
      height: 20rem; } }

.investmentsOverview-component__valuationDate___3pMc0 {
  font-size: 1.2rem; }

.investmentsOverview-component__smallerText___1QB2W {
  font-size: 1.5rem; }
