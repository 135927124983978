/* Sizes */
.banner-component__banner___UDnTf {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: relative;
  min-height: 14rem;
  background-size: cover;
  background-color: #eeebe6;
  background-repeat: no-repeat;
  background-image: url("/assets/img/banner-hp.jpg");
  background-position: top right;
  color: #363431;
  z-index: 10;
  padding-top: 2rem;
  padding-bottom: 2rem; }
  .banner-component__banner___UDnTf.banner-component__offsetBackground___1dSU9 {
    background-size: 32rem;
    background-position: 10em -1rem; }
  @media (min-width: 600px) {
    .banner-component__banner___UDnTf {
      min-height: 20rem; }
      .banner-component__banner___UDnTf.banner-component__offsetBackground___1dSU9 {
        background-position: 18em -3rem; } }
  @media (min-width: 750px) {
    .banner-component__banner___UDnTf {
      min-height: 30rem;
      background-size: 800px; }
      .banner-component__banner___UDnTf.banner-component__offsetBackground___1dSU9 {
        background-size: 50rem;
        background-position: 25em -3rem; } }
  @media (min-width: 1024px) {
    .banner-component__banner___UDnTf {
      background-size: 1143px; }
      .banner-component__banner___UDnTf.banner-component__offsetBackground___1dSU9 {
        background-size: 60rem;
        background-position: 50rem -6rem; } }
  @media (min-width: 1360px) {
    .banner-component__banner___UDnTf.banner-component__offsetBackground___1dSU9 {
      background-position: 75rem -6rem; } }
  .banner-component__banner___UDnTf .banner-component__container___38hbB {
    margin: 0 auto;
    padding-left: 2rem; }
    @media (min-width: 600px) {
      .banner-component__banner___UDnTf .banner-component__container___38hbB {
        padding-left: 0; } }
    .banner-component__banner___UDnTf .banner-component__container___38hbB h1 {
      text-transform: none; }
  .banner-component__banner___UDnTf .banner-component__smallerText___3ZJ-0 {
    text-transform: none; }
  .banner-component__banner___UDnTf h1 {
    font-family: "Maitree", serif;
    font-size: 3rem;
    color: #363431; }
    .banner-component__banner___UDnTf h1.banner-component__smallerText___3ZJ-0 {
      font-size: 1.6rem; }
    .banner-component__banner___UDnTf h1 br {
      display: none; }
    @media (min-width: 750px) {
      .banner-component__banner___UDnTf h1 {
        font-size: 5.5rem; }
        .banner-component__banner___UDnTf h1.banner-component__smallerText___3ZJ-0 {
          font-size: 3.1rem; }
        .banner-component__banner___UDnTf h1 br {
          display: block; } }
  .banner-component__banner___UDnTf h2 {
    font-family: "Gotham-Medium", Arial, sans-serif;
    font-size: 2.8rem;
    color: #363431;
    text-transform: uppercase; }
    .banner-component__banner___UDnTf h2.banner-component__smallerText___3ZJ-0 {
      font-size: 1.2rem; }
    @media (min-width: 600px) {
      .banner-component__banner___UDnTf h2 {
        font-size: 3.5rem; }
        .banner-component__banner___UDnTf h2.banner-component__smallerText___3ZJ-0 {
          font-size: 1.7rem; } }
    @media (min-width: 750px) {
      .banner-component__banner___UDnTf h2 {
        font-size: 5.5rem; }
        .banner-component__banner___UDnTf h2.banner-component__smallerText___3ZJ-0 {
          font-size: 2.9rem; } }
    .banner-component__banner___UDnTf h2 a {
      text-decoration-line: none;
      color: #363431; }
  .banner-component__banner___UDnTf hr {
    width: 50vw;
    margin: 2rem 0;
    background: #363431;
    border: 0;
    height: 2px;
    text-align: left; }
    @media (min-width: 600px) {
      .banner-component__banner___UDnTf hr {
        margin: 3rem 0 3rem -1.5rem; } }
    @media (min-width: 750px) {
      .banner-component__banner___UDnTf hr {
        width: 75vw;
        width: 320px; } }

.banner-component__backIcon___3a1rn {
  font-size: 2.8rem;
  line-height: 2.8rem; }
  @media (min-width: 600px) {
    .banner-component__backIcon___3a1rn {
      font-size: 4rem;
      line-height: 4rem; } }
