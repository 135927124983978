/* Sizes */
.accountDetailsMenu-component__account-details-menu___2mosf {
  position: relative;
  display: flex;
  align-items: center; }
  .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__browserOnly___3JkNk {
    display: none; }
    @media (min-width: 600px) {
      .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__browserOnly___3JkNk {
        display: flex; } }
  .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__accountDetailsMobileToggleContainer___k1tbd {
    font-size: 1.6rem;
    line-height: 1.6rem; }
    .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__accountDetailsMobileToggleContainer___k1tbd button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 1rem 0 0;
      border: 0;
      height: auto;
      line-height: inherit; }
  .accountDetailsMenu-component__account-details-menu___2mosf.accountDetailsMenu-component__active___V0I4i button {
    color: #ccae44; }
  .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn {
    position: absolute;
    top: 6rem;
    display: none; }
    @media (min-width: 600px) {
      .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn {
        position: relative;
        top: auto;
        display: block; } }
  .accountDetailsMenu-component__account-details-menu___2mosf.accountDetailsMenu-component__active___V0I4i .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn {
    width: 100vw;
    right: -5rem;
    display: block; }
    @media (min-width: 600px) {
      .accountDetailsMenu-component__account-details-menu___2mosf.accountDetailsMenu-component__active___V0I4i .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn {
        width: auto;
        right: auto; } }
  .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn {
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    background-color: #363431;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5); }
    .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn button {
      height: auto;
      border: 0; }
    .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn .accountDetailsMenu-component__row___NdzTv {
      display: flex;
      flex-direction: row; }
    .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn button.accountDetailsMenu-component__accountDetailsMenuToggle___1L-hs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%; }
      .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn button.accountDetailsMenu-component__accountDetailsMenuToggle___1L-hs .accountDetailsMenu-component__welcomeBack___p6iFD,
      .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn button.accountDetailsMenu-component__accountDetailsMenuToggle___1L-hs .accountDetailsMenu-component__userName___3i_1u,
      .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn button.accountDetailsMenu-component__accountDetailsMenuToggle___1L-hs .accountDetailsMenu-component__lastLogin___1FXNh {
        color: #edede9;
        margin-bottom: 0;
        text-transform: none;
        text-align: start;
        font-family: "Maitree", serif;
        line-height: normal; }
      .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn button.accountDetailsMenu-component__accountDetailsMenuToggle___1L-hs .accountDetailsMenu-component__welcomeBack___p6iFD {
        font-weight: 300;
        font-size: 1.4rem; }
      .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn button.accountDetailsMenu-component__accountDetailsMenuToggle___1L-hs .accountDetailsMenu-component__lastLogin___1FXNh {
        color: #9b9b9b;
        font-size: 1.2rem; }
      .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn button.accountDetailsMenu-component__accountDetailsMenuToggle___1L-hs .accountDetailsMenu-component__userName___3i_1u {
        text-transform: uppercase;
        font-family: "Gotham-Medium", Arial, sans-serif;
        font-size: 1.6rem;
        margin-right: 2rem;
        white-space: pre-line; }
        .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn button.accountDetailsMenu-component__accountDetailsMenuToggle___1L-hs .accountDetailsMenu-component__userName___3i_1u svg {
          position: absolute;
          right: 1rem;
          bottom: calc(50% - 1.5rem);
          font-size: 3rem; }
    .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn .accountDetailsMenu-component__accountMenuButton___21TMp {
      color: #edede9;
      border-bottom: 1px solid #fff;
      border-radius: 0;
      padding: 1rem 0px;
      font-size: 1.6rem;
      width: 100%;
      text-align: left;
      text-transform: none;
      font-family: "Maitree", serif; }
      .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn .accountDetailsMenu-component__accountMenuButton___21TMp:first-of-type {
        border-top: 1px solid #fff; }
      .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__fullAccountMenuContainer___2dGAn .accountDetailsMenu-component__accountMenuButton___21TMp:last-of-type {
        border-bottom: 0; }
  .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__openAccountMenuContainer___ytOzg {
    position: absolute;
    top: 6rem;
    width: 100vw;
    flex-direction: column;
    display: flex;
    background-color: #363431;
    width: 100%;
    align-items: baseline;
    padding: 0 30px; }
  @media (min-width: 600px) {
    .accountDetailsMenu-component__account-details-menu___2mosf.accountDetailsMenu-component__active___V0I4i .accountDetailsMenu-component__openAccountMenuContainer___ytOzg {
      left: 0;
      top: 100%;
      right: 0; } }

@media (min-width: 600px) {
  .accountDetailsMenu-component__account-details-menu___2mosf .accountDetailsMenu-component__accountDetailsMobileToggleContainer___k1tbd {
    display: none; } }
