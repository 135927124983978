/* Sizes */
.investmentDetails-component__investmentDetailsContainer___HbhfI h3 {
  color: #3d4866;
  font-family: Gotham-Medium;
  font-size: 24px;
  font-weight: 500;
  height: 24px;
  text-transform: uppercase;
  font-family: "Gotham-Medium", Arial, sans-serif; }
  .investmentDetails-component__investmentDetailsContainer___HbhfI h3:first-of-type {
    margin-bottom: 1rem; }

.investmentDetails-component__investmentDetailsContainer___HbhfI hr {
  width: 50px;
  height: 0px;
  border-top: 1px solid #979797;
  margin: 1.6rem 0; }

.investmentDetails-component__investmentDetailsContainer___HbhfI label {
  color: #3d4866;
  padding-left: 0; }

.investmentDetails-component__investmentDetailsContainer___HbhfI p {
  font-family: "Gotham-Medium", Arial, sans-serif;
  padding-left: 0; }
